import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    component: () => import('../layouts/MainLayout.vue'),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '',
        name: 'home',
        component: HomeView,
      }
    ]
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  // 建檔
  {
    path: '/management',
    name: 'management',
    component: () => import('../layouts/MainLayout.vue'),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'material',
        name: 'material',
        component: () => import ('../views/management/MaterialView.vue'),
        meta: { section: '原料建檔'}
      },
      {
        path: 'pack',
        name: 'pack',
        component: () => import ('../views/management/PackView.vue'),
        meta: { section: '包材建檔'},
        props: route => ({
          type: route.query.type
        })    
      },
      {
        path: 'content',
        name: 'content',
        component: () => import ('../views/management/ContentView.vue'),
        meta: { section: '內容物建檔'}
      },
      {
        path: 'single-product',
        name: 'singleProduct',
        component: () => import ('../views/management/SingleProduct.vue'),
        meta: { section: '單品建檔'}
      },
      {
        path: 'gift-product',
        name: 'giftProduct',
        component: () => import ('../views/management/GiftProduct.vue'),
        meta: { section: '禮品建檔'}
      },
    ]
  },
  {
    path: '/material-add',
    name: 'materialAdd',
    component: () => import ('../views/management/MaterialAdd.vue'),
    meta: { section: '原料建檔'},
    props: route => ({
      mode: route.query.mode,
      id: route.query.id
    })
  },
  {
    path: '/material-detail',
    name: 'materialDetail',
    component: () => import ('../views/management/MaterialDetail.vue'),
    meta: { section: '原料建檔'},
    props: route => ({
      mode: route.query.mode,
      id: route.query.id
    })
  },
  {
    path: '/pack-detail',
    name: 'packDetail',
    component: () => import ('../views/management/PackDetail.vue'),
    meta: { section: '包材建檔'},
    props: route => ({
      mode: route.query.mode,
      id: route.query.id,
      typeId: route.query.typeId,
      type: route.query.type
    })
  },
  {
    path: '/pack-add',
    name: 'packAdd',
    component: () => import ('../views/management/PackAdd.vue'),
    meta: { section: '包材建檔'},
    props: route => ({
      mode: route.query.mode,
      id: route.query.id,
      typeId: route.query.typeId,
      type: route.query.type
    })
  },
  {
    path: '/content-add',
    name: 'contentAdd',
    component: () => import ('../views/management/ContentAdd.vue'),
    meta: { section: '內容物建檔'},
    props: route => ({
      mode: route.query.mode,
      id: route.query.id
    })
  },
  {
    path: '/content-detail',
    name: 'contentDetail',
    component: () => import ('../views/management/ContentDetail.vue'),
    meta: { section: '內容物建檔'},
    props: route => ({
      mode: route.query.mode,
      id: route.query.id
    })
  },
  {
    path: '/single-product-add',
    name: 'singleProductAdd',
    component: () => import ('../views/management/SingleProductAdd.vue'),
    meta: { section: '單品建檔'},
    props: route => ({
      mode: route.query.mode,
      id: route.query.id
    })    
  },
  {
    path: '/single-product-detail',
    name: 'singleProductDetail',
    component: () => import ('../views/management/SingleProductDetail.vue'),
    meta: { section: '單品建檔'},
    props: route => ({
      mode: route.query.mode,
      id: route.query.id
    })    
  },
  {
    path: '/gift-product-detail',
    name: 'giftProductDetail',
    component: () => import ('../views/management/GiftProductDetail.vue'),
    meta: { section: '禮品建檔'},
    props: route => ({
      mode: route.query.mode,
      id: route.query.id
    })    
  },
  {
    path: '/gift-product-add',
    name: 'giftProductAdd',
    component: () => import ('../views/management/GiftProductAdd.vue'),
    meta: { section: '禮品建檔'},
    props: route => ({
      mode: route.query.mode,
      id: route.query.id
    })    
  },

  // 系統管理
  {
    path: '/system',
    name: 'system',
    component: () => import('../layouts/MainLayout.vue'),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'positionauthority',
        name: 'positionauthority',
        component: () => import ('../views/system/PositionAuthority.vue'),
        meta: { section: '職位權限'}
      },
      {
        path: 'staffs',
        name: 'staffs',
        component: () => import ('../views/system/StaffsView.vue'),
        meta: { section: '員工管理'}
      },
    ]
  },
  {
    path: '/staffs-detail',
    name: 'staffsDetail',
    component: () => import ('../views/system/StaffsDetail.vue'),
    meta: { section: '職位權限'},
    props: route => ({
      mode: route.query.mode,
      id: route.query.id
    })    
  },
  {
    path: '/staffs-add',
    name: 'StaffsAdd',
    component: () => import ('../views/system/StaffsAdd.vue'),
    meta: { section: '操作人員'},
    props: route => ({
      mode: route.query.mode,
      id: route.query.id
    })
  },
  {
    path: '/positionauthority-add',
    name: 'PositionAuthorityAdd',
    component: () => import ('../views/system/PositionAuthorityAdd.vue'),
    meta: { section: '職位權限'},
    props: route => ({
      mode: route.query.mode,
      id: route.query.id
    })
  },
  {
    path: '/positionauthority-detail',
    name: 'positionAuthorityDetail',
    component: () => import ('../views/system/PositionAuthorityDetail.vue'),
    meta: { section: '職位權限'},
    props: route => ({
      mode: route.query.mode,
      id: route.query.id
    })    
  },
  // 進出貨
  {
    path: '/goods',
    name: 'goods',
    component: () => import('../layouts/MainLayout.vue'),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'restock',
        name: 'restock',
        component: () => import ('../views/goods/RestockView.vue'),
        meta: { section: '進貨'}
      },
      {
        path: 'restock-record',
        name: 'restockRecord',
        component: () => import ('../views/goods/RestockRecord.vue'),
        meta: { section: '進貨'}
      },
      {
        path: 'ship',
        name: 'ship',
        component: () => import ('../views/goods/ShipView.vue'),
        meta: { section: '出貨'},
        props: route => ({
          id: route.query.id
        })
      },
      {
        path: 'ship-record',
        name: 'shipRecord',
        component: () => import ('../views/goods/ShipRecord.vue'),
        meta: { section: '出貨'}
      },
      {
        path: '/ship-record-detail',
        name: 'shipRecordDetail',
        component: () => import ('../views/goods/ShipRecordDetail.vue'),
        meta: { section: '職位權限'},
        props: route => ({
          mode: route.query.mode,
          id: route.query.id
        })    
      },
      {
        path: 'unit-convert',
        name: 'unitConvert',
        component: () => import ('../views/goods/UnitConvert.vue'),
        meta: { section: '單位轉換'}
      },
    ]
  },
  {
    path: '/unit-convert-add',
    name: 'unitConvertAdd',
    component: () => import ('../views/goods/UnitConvertAdd.vue'),
    meta: { section: '單位轉換'},
    props: route => ({
      mode: route.query.mode,
      id: route.query.id
    })
  },
  {
    path: '/unit-convert-detail',
    name: 'unitConvertDetail',
    component: () => import ('../views/goods/UnitConvertDetail.vue'),
    meta: { section: '單位轉換'},
    props: route => ({
      mode: route.query.mode,
      id: route.query.id
    })
  },
  // 新增表單
  {
    path: '/form',
    name: 'form',
    component: () => import('../layouts/MainLayout.vue'),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'pick',
        name: 'pick',
        component: () => import ('../views/form/PickView.vue'),
        meta: { section: '領料單'}
      },
      {
        path: 'pick-record',
        name: 'pickRecord',
        component: () => import ('../views/form/PickRecord.vue'),
        meta: { section: '領料單'}
      },
      {
        path: 'overage',
        name: 'overage',
        component: () => import ('../views/form/OverageView.vue'),
        meta: { section: '餘料單'}
      },
      {
        path: 'overage-record',
        name: 'overageRecord',
        component: () => import ('../views/form/OverageRecord.vue'),
        meta: { section: '餘料單'}
      },
    ]
  },
  {
    path: '/pick-add',
    name: 'pickAdd',
    component: () => import ('../views/form/PickAdd.vue'),
    meta: { section: '領料單'},
    props: route => ({
      mode: route.query.mode,
      id: route.query.id,
      type: route.query.type
    })
  },
  {
    path: '/pick-record-detail',
    name: 'pickRecordDetail',
    component: () => import ('../views/form/PickRecordDetail.vue'),
    meta: { section: '領料單'},
    props: route => ({
      id: route.query.id,
      type: route.query.type
    })
  },
  {
    path: '/overage-add',
    name: 'overageAdd',
    component: () => import ('../views/form/OverageAdd.vue'),
    meta: { section: '餘料單'},
    props: route => ({
      mode: route.query.mode,
      id: route.query.id,
      type: route.query.type
    })
  },
  {
    path: '/overage-record-detail',
    name: 'overageRecordDetail',
    component: () => import ('../views/form/OverageRecordDetail.vue'),
    meta: { section: '餘料單'},
    props: route => ({
      id: route.query.id,
      type: route.query.type
    })
  },
  // 開始製作
  {
    path: '/make',
    name: 'make',
    component: () => import('../layouts/MainLayout.vue'),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'content-make',
        name: 'contentMake',
        component: () => import ('../views/make/ContentMake.vue'),
        meta: { section: '內容製作'}
      },
      {
        path: 'content-status',
        name: 'contentStatus',
        component: () => import ('../views/make/ContentStatus.vue'),
        meta: { section: '內容製作狀況'}
      },
      {
        path: 'pack-make',
        name: 'packMake',
        component: () => import ('../views/make/PackMake.vue'),
        meta: { section: '包材使用'}
      },
      {
        path: 'pack-status',
        name: 'packStatus',
        component: () => import ('../views/make/PackStatus.vue'),
        meta: { section: '包材使用狀況'}
      },
      {
        path: '/detail',
        name: 'packStatusDetail',
        component: () => import ('../components/pack-status/PackStatusDetail.vue'),
        // meta: { section: '包材使用狀況'},
        props: route => ({
          startDate: route.query.startDate,
          endDate: route.query.endDate,
          id: route.query.id
        })
      },
      {
        path: 'temporary',
        name: 'temporaryView',
        component: () => import ('../views/make/TemporaryView.vue'),
        meta: { section: '包材暫存區'}
      },
      {
        path: 'temporary-record',
        name: 'temporaryRecord',
        component: () => import ('../views/make/TemporaryRecord.vue'),
        meta: { section: '包材暫存區'}
      },
    ]
  },
  {
    path: '/content-make-add',
    name: 'contentMakeAdd',
    component: () => import ('../views/make/ContentMakeAdd.vue'),
    meta: { section: '內容物製作'},
    props: route => ({
      mode: route.query.mode,
      id: route.query.id
    })    
  },
  {
    path: '/content-make-detail',
    name: 'contentMakeDetail',
    component: () => import ('../views/make/ContentMakeDetail.vue'),
    meta: { section: '內容物製作狀況'},
    props: route => ({     
      start: route.query.start,
      end: route.query.end,
      id: route.query.id,
    })    
  },
  {
    path: '/pack-make-add',
    name: 'packMakeAdd',
    component: () => import ('../views/make/PackMakeAdd.vue'),
    meta: { section: '包材使用'},
    props: route => ({
      mode: route.query.mode,
      id: route.query.id
    })    
  },
  {
    path: '/pack-make-detail',
    name: 'packMakeDetail',
    component: () => import ('../views/make/PackMakeDetail.vue'),
    meta: { section: '包材使用狀況'},
    props: route => ({     
      start: route.query.start,
      end: route.query.end,
      item: route.query.item,
      id: route.query.id,
    })    
  },
  // 倉庫管理
  {
    path: '/storehouse',
    name: 'storehouse',
    component: () => import('../layouts/MainLayout.vue'),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'warehouse',
        name: 'warehouse',
        component: () => import ('../views/storehouse/WarehouseView.vue'),
        meta: { section: '入庫'}
      },
      {
        path: 'warehouse/record',
        name: 'warehouseRecord',
        component: () => import ('../views/storehouse/WarehouseRecord.vue'),
        meta: { section: '入庫'}
      },
      {
        path: 'stockout',
        name: 'stockout',
        component: () => import ('../views/storehouse/StockOut.vue'),
        meta: { section: '出庫'}
      },
      {
        path: 'stockout-record',
        name: 'stockOutRecord',
        component: () => import ('../views/storehouse/StockOutRecord.vue'),
        meta: { section: '出庫'}
      },
      {
        path: 'restock-status',
        name: 'restockStatus',
        component: () => import ('../views/storehouse/RestockStatus.vue'),
        meta: { section: '庫存狀態'}
      },
      {
        path: 'restock-status-detail',
        name: 'restockStatusDetail',
        component: () => import ('../views/storehouse/RestockStatusDetail.vue'),
        meta: { section: '庫存狀態'},
        props: route => ({
          type: route.query.type,
          property: route.query.property,
          id: route.query.id,
        })
      },
      {
        path: 'restock-status-record',
        name: 'restockStatusRecord',
        component: () => import ('../views/storehouse/RestockStatusRecord.vue'),
        meta: { section: '庫存狀態'},        
      },
      {
        path: 'inventory',
        name: 'warehouseInventory',
        component: () => import ('../views/storehouse/WarehouseInventory.vue'),
        meta: { section: '庫存盤點'},        
      },
      {
        path: 'inventory-record',
        name: 'inventoryRecord',
        component: () => import ('../views/storehouse/InventoryRecord.vue'),
        meta: { section: '庫存盤點'},        
      },
    ]
  },
  {
    path: '/stockout-add',
    name: 'stockOutDetail',
    component: () => import ('../views/storehouse/StockOutDetail.vue'),
    meta: { section: '出庫'},
    props: route => ({
      mode: route.query.mode,
      id: route.query.id,
      type: route.query.type
    })
  },
  {
    path: '/stockout-record-detail',
    name: 'stockOutRecordDetail',
    component: () => import ('../views/storehouse/StockOutRecordDetail.vue'),
    meta: { section: '出庫'},
    props: route => ({
      mode: route.query.mode,
      id: route.query.id,
      type: route.query.type
    })
  },
  {
    path: '/restock-status-record-detail',
    name: 'restockStatusRecordDetail',
    component: () => import ('../views/storehouse/RestockStatusRecordDetail.vue'),
    meta: { section: '庫存狀態'},
    props: route => ({
      type: route.query.type,
      id: route.query.id,
    })
  },
  {
    path: '/remainder-detail',
    name: 'remainderAdd',
    component: () => import ('../views/storehouse/RemainderAdd.vue'),
    meta: { section: '餘料入庫'},
    props: route => ({
      mode: route.query.mode,
      type: route.query.type,
      id: route.query.id,
    })
  },

  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
